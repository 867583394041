<template>
  <div class="ma-3">
    <v-toolbar>
      <v-toolbar-title>Library Archive Categories</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn v-if="isAdmin" class="mr-4" @click="categoryClick({ _id: '', name: '' })">
        <v-icon left small>fal fa-plus-circle</v-icon>
        Add Category
      </v-btn>
    </v-toolbar>
    <v-btn to="/library/archives/All" style="transform:translateX(-4px)" class="ma-2 ml-1">Search the Full Archives</v-btn>
    <v-row>
      <v-col :cols="12" md="6">
        <v-card>
          <v-list>
            <v-toolbar flat dense>
              <v-subheader class="pl-0">Archive Categories</v-subheader>
              <v-spacer></v-spacer>
              <v-text-field v-model="categoryFilter" label="Filter List of Categories" outlined hide-details dense clearable style="width:150px">
                <template v-slot:prepend-inner>
                  <v-icon small style="margin-top:4px">fal fa-search</v-icon>
                </template>
              </v-text-field>
            </v-toolbar>
            <v-list-item v-for="{ _id, name, label } of categories" :key="label" :to="'/library/archives/' + name">
              <v-list-item-title>{{ label }}</v-list-item-title>
              <v-list-item-action v-if="isAdmin">
                <v-btn icon @click="(e) => categoryClick({ _id, name }, e)">
                  <v-icon>fal fa-pencil</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col :cols="12" md="6">
        <v-card>
          <v-list>
            <v-subheader>Additional Links</v-subheader>
            <v-list-item v-for="{ link, title, subtitle } in links" :key="link" @click="openLink(link)">
              <v-list-item-content>
                <v-list-item-title>{{ title }}</v-list-item-title>
                <v-list-item-subtitle>{{ subtitle }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <!-- <v-row class="pl-4 pr-4 pb-2">
      <v-col v-for="{ _id, name, label, count, last, thumbnail, active } of categories" :key="label" cols="12" md="6" lg="4" xl="3" class="pa-0">
        <archive-card :thumbnail="thumbnail" :title="label" :subtitle="'Contains ' + (count || 'no') + ' entries'" :date="last ? 'Last entry from: ' + stringFormatDate(last).substring(4, 17) : (active ? 'Section is active' : 'Section is not active')" @click="categoryClick({ _id, name })"></archive-card>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-card-title>Additional Links</v-card-title>
    <v-row class="pl-4 pr-4 pt-2 pb-2">
      <v-col v-for="{ link, title, subtitle } in links" :key="link" cols="12" md="6" lg="4" xl="3" class="pa-0">
        <archive-card :title="title" :subtitle="subtitle || ' '" hide-thumbnail @click="openLink(link)"></archive-card>
      </v-col>
    </v-row> -->
    <category-dialog v-if="isAdmin" ref="categoryDialog" :id="selectedCategory" @update="loadCategories"></category-dialog>
  </div>
</template>
<script>
import { ref, computed, onMounted, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    // ArchiveCard: () => import('@/components/library/archives/card'),
    CategoryDialog: () => import('@/components/library/archives/categoryDialog')
  },
  setup (props, { root }) {
    const isLoggedIn = computed(() => root.$store.state.loggedIn)
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isAdmin = computed(() => user.value.roles && user.value.roles.filter((role) => role === 'Library' || role === 'Technology Services').length > 0)
    const categories = computed({
      get: () => {
        const arr = root.$store.state.library.archives.categories.filter(({ active, loginRequired }) => isAdmin.value || (active && (!loginRequired || isLoggedIn.value)))
        if (categoryFilter.value != null && categoryFilter.value !== '') {
          const regex = new RegExp(categoryFilter.value, 'ig')
          return arr.filter(({ label }) => label.match(regex))
        }
        return arr
      },
      set: (val) => root.$store.commit('library/setArchiveCategories', val)
    })
    const headers = computed(() => {
      if (isAdmin.value) {
        return [
          { text: 'Thumbnail', value: 'thumbnail', sortable: false },
          { text: 'Name', value: 'name' },
          { text: 'Visible Label', value: 'label' },
          { text: 'Item Count', value: 'count' },
          { text: 'Last Entry', value: 'last' },
          { text: 'Active?', value: 'active' },
          { text: 'Login Required?', value: 'loginRequired' },
          { text: 'Google Drive Folder', value: 'folder' }
        ]
      } else {
        return [
          { text: 'Thumbnail', value: 'thumbnail', sortable: false },
          { text: 'Category', value: 'label' },
          { text: 'Item Count', value: 'count' },
          { text: 'Last Entry', value: 'last' },
          { text: 'View Entries', value: 'action' }
        ]
      }
    })

    onMounted(async () => {
      if (categories.value.length === 0) {
        await loadCategories()
      }
    })

    async function loadCategories () {
      await root.$store.dispatch('library/loadArchiveCategories')
    }

    const categoryDialog = ref(null)
    const selectedCategory = ref(null)
    function categoryClick ({ _id, name }, e) {
      e.preventDefault()
      console.log(_id, name)
      if (isAdmin.value) {
        selectedCategory.value = _id
        categoryDialog.value.activate()
      } else {
        root.$router.push('/library/archives/' + name.replaceAll(' ', '_'))
      }
    }

    const links = ref([
      { link: 'https://soundcloud.com/covenantcollege/sets', title: 'Covenant College Chapel on SoundCloud', subtitle: '2012 - 2020' },
      { link: 'https://www.youtube.com/user/CovenantCollege', title: 'Covenant College on YouTube' },
      { link: 'https://portal.covenant.edu/library/archives/Capstone', title: 'SIP and Capstone Archive', subtitle: 'Covenant Login Required' },
      { link: 'https://www.pcahistory.org/pca/ga/index.html', title: 'Minutes of the General Assembly of the PCA', subtitle: '1973 - Present' }
      // { link: 'https://archive.covenant.edu/news/archive', title: 'Covenant.edu Website Archives', subtitle: '2008 - 2020' }
    ])
    function openLink (link) {
      window.open(link)
    }

    function viewCapstones () {
      root.$store.commit('setLoginRedirectUrl', '/library/archives/Capstone')
      root.$router.push('/login')
    }

    const filterActive = ref(false)
    const categoryFilterField = ref(null)
    const categoryFilter = ref('')
    watch(filterActive, () => {
      if (filterActive.value) {
        categoryFilterField.value.focus()
      }
    })

    return {
      user,
      isAdmin,
      categories,
      headers,
      loadCategories,
      categoryDialog,
      selectedCategory,
      categoryClick,
      links,
      openLink,
      viewCapstones,
      filterActive,
      categoryFilterField,
      categoryFilter,
      stringFormatDate
    }
  }
}
</script>
