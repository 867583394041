<template>
  <v-list>
    <v-list-item v-for="{ title, crn, meets, meetingBase, instructor, regStatus, credits, gradeMode } in schedule" :key="'course-'+crn">
      <v-list-item-avatar v-if="regStatus === 'WL'">
        <v-icon color="error">fas fa-exclamation-triangle</v-icon>
      </v-list-item-avatar>
      <v-list-item-avatar v-else-if="regStatus === 'DC'">
        <v-icon color="error">fas fa-times-circle</v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title :style="regStatus === 'DC' ? 'text-decoration:line-through' : ''">{{ title }}</v-list-item-title>
        <v-list-item-subtitle v-if="meets || getMeetString(meetingBase)" :style="regStatus === 'DC' ? 'text-decoration:line-through' : ''">
          <b style="vertical-align:top">Meets: </b>
          <span v-html="meets || getMeetString(meetingBase)" style="display:inline-block"></span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-if="instructor" :style="regStatus === 'DC' ? 'text-decoration:line-through' : ''"><b>Instructor: </b>{{ typeof (instructor) === 'object' ? instructor.name : instructor }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="regStatus === 'DC'" style="font-weight:bold">{{ isEmployee ? 'Student has' : 'You have' }} dropped this course</v-list-item-subtitle>
        <template v-else>
          <v-list-item-subtitle v-if="regStatus === 'WL'" style="font-weight:bold">{{ isEmployee ? 'Student is' : 'You are' }} currently wait-listed for this course</v-list-item-subtitle>
          <v-list-item-subtitle v-if="credits">Credits: {{ credits }}</v-list-item-subtitle>
          <v-list-item-subtitle v-if="gradeMode && gradeMode === 'pass-fail'">
            <v-chip label>Grade Mode is Pass/Fail</v-chip>
          </v-list-item-subtitle>
        </template>
      </v-list-item-content>
    </v-list-item>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-subtitle>Total credit hours: {{ credits }}</v-list-item-subtitle>
        <v-list-item-subtitle v-if="updatedAt">Last Updated from Banner: {{ updatedAt }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>
<script>
import { computed } from '@vue/composition-api'
export default {
  props: {
    term: {
      type: String,
      required: true
    },
    schedule: {
      type: Array,
      required: true
    },
    updatedAt: {
      type: String,
      default: ''
    }
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const isEmployee = computed(() => user.value.roles.includes('Employee'))
    const credits = computed(() => {
      let ret = props.schedule.reduce((acc, { regStatus, credits }) => acc + (['WL', 'DC'].includes(regStatus) ? 0 : credits), 0)
      const waitListCount = props.schedule.filter(({ regStatus }) => regStatus === 'WL').length
      if (waitListCount > 0) {
        ret += ' (' + props.schedule.reduce((acc, { regStatus, credits }) => acc + (regStatus === 'DC' ? 0 : credits), 0) + ' with wait-listed course' + (waitListCount === 1 ? '' : 's') + ')'
      }
      return ret
    })

    function getMeetString (meetingBase) {
      return meetingBase.map(({ days, startTime, endTime, room, building }) => {
        let arr = [days]
        if (startTime != null && startTime !== '' && endTime != null && endTime !== '') arr.push(startTime + ' - ' + endTime)
        if (room != null && room !== '') arr.push(' in ' + room + (building != null && building !== '' ? ' (' + building + ')' : ''))
        return arr.join(' ')
      }).join('<br/>')
    }

    return {
      user,
      isEmployee,
      credits,
      getMeetString
    }
  }
}
</script>
