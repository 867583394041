<template>
  <div class="ma-4" style="height:100%">
    <admin-header>
      <template v-slot:left>
        <v-select v-model="calendarView" :items="calendarOptions" label="Calendar View" class="ml-6" dense outlined hide-details style="max-width:200px"></v-select>
      </template>
      <template v-slot:right>
        <jury-help-dialog></jury-help-dialog>
      </template>
    </admin-header>
    <v-card class="mt-4">
      <v-row>
        <v-col cols="9">
          <v-sheet height="100%">
            <v-calendar
              ref="calendar"
              :events="filteredEvents"
              :start="start"
              :end="end"
              :weekdays="weekdays"
              type="week"
              first-interval="30"
              interval-minutes="20"
              interval-count="16"
              @click:event="clickEvent"
              ></v-calendar>
          </v-sheet>
          <v-dialog v-model="editDialog" width="400">
            <v-card>
              <v-card-title>Edit Inactive Slot</v-card-title>
              <v-card-text>
                <p>Editing slot on {{ editTime }}</p>
                <v-tabs v-model="editTab">
                  <v-tab>Activate</v-tab>
                  <v-tab>Assign Student</v-tab>
                </v-tabs>
                <v-tabs-items v-model="editTab">
                  <v-tab-item>
                    <p>To activate the slot so students can choose it, click the button below.</p>
                    <v-btn color="success">Activate Slot</v-btn>
                  </v-tab-item>
                  <v-tab-item>
                    <p>To assign a student to this slot, select the student, their instrument, and their instructor, then click "Save".</p>
                    <directory-search v-model="editStudent" :person-filter="['Student']" label="Student Search"></directory-search>
                    <v-select v-model="editInstructor" :items="instructors" label="Instructor" item-value="name" item-text="name" outlined />
                    <v-select v-model="editInstrument" :items="instruments" label="Instrument" outlined />
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="editDialog = false">Close</v-btn>
                <v-btn v-if="editTab === 1" color="success" :disabled="editStudent === '' || editInstructor === '' || editInstrument === ''" @click="assignStudent()">Save</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="3">
          <v-card class="mt-4 mr-4" style="height:100%">
            <v-card-text>
              <div v-if="calendarView === 'instr'">
                <v-list dense>
                  <v-list-item v-for="{ name, color } in instructors" :key="name" class="pl-0 pr-0" @click="toggleAvail('instr', name)">
                    <v-list-item-action class="mr-1">
                      <v-icon small>{{ instructorFilter.includes(name) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                    <v-list-item-action class="ma-0">
                      <v-icon :color="color" small>fas fa-square</v-icon>
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
              </div>
              <div v-else-if="calendarView === 'accomp'">
                <v-list dense>
                  <v-list-item v-for="{ text } in accompanists" :key="text" class="pl-0 pr-0" @click="toggleAvail('accomp', text)">
                    <v-list-item-action class="mr-1">
                      <v-icon small>{{ accompanistFilter.includes(text) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-title>{{ text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
              <div v-else-if="calendarView === 'slots'">
                <v-checkbox v-model="filter.activeOnly" label="Show Active Slots Only" class="mt-0"></v-checkbox>
                <v-text-field v-model="filter.name" label="Name Search" outlined></v-text-field>
                <v-select v-model="instrumentFilter" :items="instruments" label="Instruments" outlined multiple chips small-chips>
                  <template v-slot:item="{ item }">
                    <v-list-item-avatar>
                      <v-icon>{{ instrumentFilter.includes(item) ? 'fas fa-check-square' : 'fal fa-square' }}</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
                <v-select v-if="selectedInstructors.length > 0" v-model="instructorFilter" :items="selectedInstructors" label="Selected Instructors" item-text="name" item-value="name" outlined multiple chips small-chips />
                <v-select v-if="selectedAccompanists.length > 0" v-model="accompanistFilter" :items="selectedAccompanists" label="Selected Accompanists" item-text="name" item-value="name" outlined multiple chips small-chips />
                <v-divider />
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="on" outlined>Actions</v-btn>
                  </template>
                  <v-list style="max-width:355px">
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Inactivate Open Slots</v-list-item-title>
                        <v-list-item-subtitle>Makes any active, open slot unavailable to students</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title>Activate Open Slots</v-list-item-title>
                        <v-list-item-subtitle>Makes all inactive slots available to students</v-list-item-subtitle>
                        <v-list-item-subtitle>Except those overlapping populated slots</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { stringFormatDate } from '@/helpers/formatters'

export default {
  components: {
    AdminHeader: () => import('@/components/student/music/admin/header'),
    JuryHelpDialog: () => import('@/components/student/music/juryHelpDialog'),
    DirectorySearch: () => import('@/components/greatscots/searchField')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('student/music/jury-slot')

    const term = computed({
      get: () => root.$store.state.student.music.admin.term,
      set: (term) => root.$store.commit('student/setMusicAdmin', { term })
    })

    const calendarView = ref('slots')
    const calendarOptions = ref([{ text: 'Student Slots', value: 'slots' }, { text: 'Instructor Availability', value: 'instr' }, { text: 'Accompanist Availability', value: 'accomp' }])

    const slots = ref([])
    const events = ref([])
    const start = ref('2024-04-26')
    const end = ref('2024-04-30')
    const weekdays = ref([5, 1, 2])

    const instructors = ref([])
    const accompanists = ref([])
    const instruments = ref([])
    const selectedInstructors = ref([])
    const selectedAccompanists = ref([])
    const filter = ref({
      activeOnly: false,
      avail: {
        instr: [],
        accomp: []
      },
      name: '',
      accompanists: []
    })
    const instructorFilter = computed({
      get: () => root.$store.state.student.music.admin.instructor,
      set: (instructor) => root.$store.commit('student/setMusicAdmin', { instructor })
    })
    const accompanistFilter = computed({
      get: () => root.$store.state.student.music.admin.accompanist,
      set: (accompanist) => root.$store.commit('student/setMusicAdmin', { accompanist })
    })
    const instrumentFilter = computed({
      get: () => root.$store.state.student.music.admin.instrument,
      set: (instrument) => root.$store.commit('student/setMusicAdmin', { instrument })
    })
    function toggleAvail (field, text) {
      if (field === 'instr') {
        const index = instructorFilter.value.findIndex((n) => n === text)
        if (index >= 0) instructorFilter.value.splice(index, 1)
        else instructorFilter.value.push(text)
      } else if (field === 'accomp') {
        const index = accompanistFilter.value.findIndex((n) => n === text)
        if (index >= 0) accompanistFilter.value.splice(index, 1)
        else accompanistFilter.value.push(text)
      }
    }

    const filteredEvents = computed(() => {
      if (calendarView.value === 'slots') {
        return events.value.filter(({ name, active, instructor, instrument, accompanist }) => {
          if (filter.value.activeOnly && !active) return false
          if (instructorFilter.value.length > 0 && !instructorFilter.value.includes(instructor)) return false
          if (instrumentFilter.value.length > 0 && !instrumentFilter.value.includes(instrument)) return false
          if (filter.value.accompanists.length > 0 && !filter.value.accompanists.includes(accompanist)) return false
          if (filter.value.name !== '' && name.search(new RegExp(filter.value.name, 'ig')) < 0) return false
          return true
        })
      } else if (calendarView.value === 'instr') {
        const arr = []
        for (const { name, color, availability } of instructors.value) {
          if (instructorFilter.value.length > 0 && !instructorFilter.value.includes(name)) continue
          availability.forEach(({ start, end }) => {
            arr.push({ name, color, start: new Date(start), end: new Date(end), timed: true })
          })
        }
        return arr
      } else if (calendarView.value === 'accomp') {
        const arr = []
        for (const { name, color, availability } of accompanists.value) {
          if (accompanistFilter.value.length > 0 && !accompanistFilter.value.includes(name)) continue
          availability.forEach(({ start, end }) => {
            arr.push({ name, color, start: new Date(start), end: new Date(end), timed: true })
          })
        }
        return arr
      }
    })

    watch(term, async (newTerm, oldTerm) => {
      console.log(newTerm)
      console.log(oldTerm)
      const colors = ['red', 'pink', 'purple', 'deep-purple', 'indigo', 'blue', 'light-blue', 'cyan', 'teal', 'green', 'light-green', 'lime', 'yellow lighten-1', 'amber lighten-1', 'orange ligten-1', 'deep-orange', 'brown', 'blue-grey']
      const { data } = await root.$feathers.service('student/music/jury-setup').find({ query: { term: term.value } })
      if (data.length > 0) {
        const days = data[0].days.map((day) => day.substring(0, 10))
        days.sort()
        weekdays.value = []
        days.forEach((date) => {
          const dt = new Date(date)
          dt.setHours(10)
          if (dt.toISOString().substring(0, 10) !== date) {
            dt.setDate(dt.getDate() + 1)
          }
          weekdays.value.push(dt.getDay())
        })
        start.value = days[0]
        end.value = days[days.length - 1]
        instructors.value = data[0].instructors.map(({ name, availability }, index) => { return { name, availability, color: colors[index] } })
        accompanists.value = data[0].accompanists.map(({ name, availability }, index) => { return { name, availability, color: colors[index] } })
        instruments.value = data[0].instruments.map(({ name }) => name)
      } else {
        if (oldTerm === '' || oldTerm == null) {
          console.log('Loading latest set-up term')
          const { data } = await root.$feathers.service('student/music/jury-setup').find({ query: { $sort: { term: -1 }, $limit: 1 } })
          term.value = data[0].term
        } else {
          alert('The selected term has not been set up yet. Please go to the Jury Setup page to do the setup for the ' + term.value + ' term. The term has been set to the latest active term.')
        }
      }
      // loadInstructors()
      loadSlots()
      // loadFilters()
    })

    async function loadSlots () {
      events.value = []
      const aggregate = [
        { $match: { term: term.value } },
        { $lookup: {
          from: 'Directory',
          localField: 'student',
          foreignField: '_id',
          as: 'student',
          pipeline: [
            { $project: {
              name: { $concat: ['$name.first', ' ', '$name.last'] }
            } }
          ]
        } },
        { $project: {
          _id: 1,
          start: 1,
          end: 1,
          active: 1,
          instrument: 1,
          student: { $first: '$student' }
        } },
        { $project: {
          _id: 1,
          start: 1,
          end: 1,
          active: 1,
          instrument: 1,
          name: {
            $ifNull: ['$student.name', { $cond: { if: { $eq: ['$active', true] }, then: 'Open', else: 'Inactive' } }]
          },
          color: {
            $cond: {
              if: { $eq: [{ $ifNull: ['$student.name', ''] }, ''] },
              then: { $cond: { if: { $eq: ['$active', true] }, then: 'grey', else: 'black' } },
              else: 'success' }
          }
        } },
        { $sort: { start: 1 } }
      ]
      const { data } = await service.find({ query: { aggregate } })
      events.value = data.map((row) => {
        const startTemp = row.start.replace('T', '-').replaceAll(':', '-').split('-')
        const stHour = parseInt(startTemp[3]) - 5
        const start = new Date(startTemp[0], parseInt(startTemp[1] - 1), startTemp[2], stHour, startTemp[4])
        const endTemp = row.end.replace('T', '-').replace(':', '-').split('-')
        const endHour = parseInt(endTemp[3]) - 5
        const end = new Date(endTemp[0], parseInt(endTemp[1]) - 1, endTemp[2], endHour, endTemp[3])
        return { ...row, start, end, timed: true }
      })
    }

    async function clickEvent ({ nativeEvent, event }) {
      nativeEvent.stopPropagation()
      console.log(event)
      if (event.name.substring(0, 4) === 'Open' || event.name.substring(0, 8) === 'Inactive') {
        editTime.value = stringFormatDate(event.start)
        editId.value = event._id
        editDialog.value = true
      } else if (calendarView.value === 'slots') {
        root.$router.push('/student/music/jury/' + event._id)
      }
    }

    const addTimeOptions = ref([])
    const addInstructorDialog = ref(false)
    const addInstructor = ref('')
    const addInstructorTimes = ref([])
    const addAccompanistDialog = ref(false)
    const addAccompanist = ref('')
    const addAccompanistTimes = ref([])
    function addAvail (field) {
      if (field === 'instructor') {
        instructors.value.push({ text: addInstructor.value, value: addInstructor.value })
        filter.value.avail.instr = [addInstructor.value]
        addInstructorDialog.value = false
        addInstructor.value = ''
      } else if (field === 'accompanist') {
        accompanists.value.push({ text: addAccompanist.value, value: addAccompanist.value })
        filter.value.avail.accomp = [addAccompanist.value]
        addAccompanistDialog.value = false
        addAccompanist.value = ''
      }
    }
    function toggleTime (type, value) {
      if (type === 'instr') {
        for (let i = 0; i < addInstructorTimes.value.length; i++) {
          if (addInstructorTimes.value[i] === value) {
            addInstructorTimes.value.splice(i, 1)
            return
          }
        }
        addInstructorTimes.value.push(value)
      } else if (type === 'accom') {
        for (let i = 0; i < addAccompanistTimes.value.length; i++) {
          if (addAccompanistTimes.value[i] === value) {
            addAccompanistTimes.value.splice(i, 1)
            return
          }
        }
        addAccompanistTimes.value.push(value)
      }
    }

    const editDialog = ref(false)
    const editId = ref('')
    const editTime = ref('')
    const editTab = ref(0)
    const editStudent = ref('')
    const editInstrument = ref('')
    const editInstructor = ref('')

    async function assignStudent () {
      const id = editId.value
      const student = editStudent.value.value
      const instructor = editInstructor.value
      const instrument = editInstrument.value

      await service.patch(id, { student, instructor, instrument })
      editDialog.value = false
      loadSlots()
    }

    return {
      term,
      calendarView,
      calendarOptions,
      slots,
      events,
      start,
      end,
      weekdays,
      instructors,
      accompanists,
      instruments,
      selectedInstructors,
      selectedAccompanists,
      filter,
      instrumentFilter,
      instructorFilter,
      accompanistFilter,
      toggleAvail,
      filteredEvents,
      clickEvent,
      addTimeOptions,
      addInstructorDialog,
      addInstructor,
      addInstructorTimes,
      addAccompanistDialog,
      addAccompanist,
      addAccompanistTimes,
      addAvail,
      toggleTime,
      editDialog,
      editId,
      editTime,
      editTab,
      editStudent,
      editInstrument,
      editInstructor,
      assignStudent,
      stringFormatDate
    }
  }
}
</script>
